<template>
  <div>
    <v-row>
      <v-col
        v-if="isNaN(amountBalance.balance)"
        cols="12"
        :sm="`${isPrepaye? '3':'4'}`"
        color="grey"
        class="pa-3"
      >
        <v-skeleton-loader
          class="mx-auto"
          type="article"
        ></v-skeleton-loader>
      </v-col>
      <v-col
        v-else
        cols="12"
        :sm="`${isPrepaye? '3':'4'}`"
      >
        <v-card v-if="isPrepaye">
          <v-card-title class="align-start grey">
            <span class="text-blod">solde du compte</span>
            <v-tooltip
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2"
                  icon
                  v-bind="attrs"
                  primary
                  outlined
                  x-small
                  v-on="on"
                >
                  <h2>?</h2>
                </v-btn>
              </template>
              <span>Etat de votre solde financier</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text class="pt-4">
            <span class="text-xl font-weight-bold text-h4 vert"> {{ withCurrency(amountBalance.balance) }}</span>
            <br>
            <v-btn
              color="primary"
              class="mt-2"
              @click="rechargeForm"
            >
              Recharger
            </v-btn>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-title class="align-start grey">
            <span class="text-bold subtitle-1">Encours</span>
            <v-tooltip
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2"
                  icon
                  v-bind="attrs"
                  primary
                  outlined
                  x-small
                  v-on="on"
                >
                  <h2>?</h2>
                </v-btn>
              </template>
              <span>Montant des opérations impayées à date</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text class="pt-4">
            <span class="text-xl font-weight-bold text-h4 rouge"> {{ withCurrency(amountBalance.balance) }}</span>
            <br>
            <v-btn
              color="primary"
              class="mt-2"
              @click="debtForm"
            >
              Payer
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-if="isNaN(expenseAmount.Depenses)"
        cols="12"
        :sm="`${isPrepaye? '3':'4'}`"
        color="grey"
        class="pa-3"
      >
        <v-skeleton-loader
          class="mx-auto"
          type="article"
        ></v-skeleton-loader>
      </v-col>
      <v-col
        v-else
        cols="12"
        :sm="`${isPrepaye? '3':'4'}`"
      >
        <v-card>
          <v-card-title class="align-start grey">
            <span class="text-bold subtitle-1">Consommation en cours</span>
            <v-tooltip
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2"
                  icon
                  v-bind="attrs"
                  primary
                  outlined
                  x-small
                  v-on="on"
                >
                  <h2>?</h2>
                </v-btn>
              </template>
              <span>Montant des dépenses en cours</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text class="pt-4">
            <span class="text-xl font-weight-bold text-h4 rouge">{{ withCurrency(expenseAmount.ConsommationInProgress!==undefined?expenseAmount.ConsommationInProgress:0) }}</span>
            <br>
            <v-btn
              color="primary"
              class="mt-2"
              @click="listeOperation"
            >
              Consulter
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        v-if="isPrepaye && isNaN(expenseAmount.Depenses)"
        cols="12"
        :sm="`${isPrepaye? '3':'4'}`"
        color="grey"
        class="pa-3"
      >
        <v-skeleton-loader
          class="mx-auto"
          type="article"
        ></v-skeleton-loader>
      </v-col>
      <v-col
        v-else-if="isPrepaye"
        cols="12"
        :sm="`${isPrepaye? '3':'4'}`"
      >
        <v-card>
          <v-card-title class="align-center grey">
            <span class="text-bold subtitle-1">Consommation</span>
            <v-tooltip
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2"
                  icon
                  v-bind="attrs"
                  primary
                  outlined
                  x-small
                  v-on="on"
                >
                  <h2>?</h2>
                </v-btn>
              </template>
              <span>Montant des dépenses réalisées à date</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text class="pt-4">
            <span class="text-xl font-weight-bold text-h4 rouge">{{ withCurrency(expenseAmount.Consommation!==undefined?expenseAmount.Consommation:0) }}</span>
            <br>
            <v-btn
              color="primary"
              class="mt-2"
              @click="listeOperationTraite"
            >
              Consulter
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-if="isNaN(totalExpenses)"
        cols="12"
        :sm="`${isPrepaye? '3':'4'}`"
        color="grey"
        class="pa-3"
      >
        <v-skeleton-loader
          class="mx-auto"
          type="article"
        ></v-skeleton-loader>
      </v-col>
      <v-col
        v-else
        cols="12"
        :sm="`${isPrepaye? '3':'4'}`"
      >
        <template v-if="isPrepaye">
          <v-card>
            <v-card-title class="align-center grey">
              <span class="text-bold subtitle-1">Consommation à venir</span>
              <v-tooltip
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2"
                    icon
                    v-bind="attrs"
                    primary
                    outlined
                    x-small
                    v-on="on"
                  >
                    <h2>?</h2>
                  </v-btn>
                </template>
                <span>Montant des opérations arrivant à échéance dans les 5 prochains jours</span>
              </v-tooltip>
            </v-card-title>
            <v-card-text class="pt-4">
              <span class="text-xl font-weight-bold text-h4 orange">{{ withCurrency(totalExpenses) }}</span>
              <br>
              <v-btn
                color="primary"
                class="mt-2"
                @click="gotoEcheanceList"
              >
                Consulter
              </v-btn>
            </v-card-text>
          </v-card>
        </template>
        <template v-else>
          <v-card>
            <v-card-title class="align-center grey">
              <span class="text-bold subtitle-1">Dépenses à venir</span>
              <v-tooltip
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2"
                    icon
                    v-bind="attrs"
                    primary
                    outlined
                    x-small
                    v-on="on"
                  >
                    <h2>?</h2>
                  </v-btn>
                </template>
                <span>Montant des opérations arrivant à échéance dans les 5 prochains jours</span>
              </v-tooltip>
            </v-card-title>
            <v-card-text class="pt-4">
              <span class="text-xl font-weight-bold text-h4 orange">{{ withCurrency(totalExpenses) }}</span>
              <br>
              <v-btn
                color="primary"
                class="mt-2"
                @click="gotoEcheanceList"
              >
                Consulter
              </v-btn>
            </v-card-text>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiTrendingDown,
  mdiCheck,
  mdiClose,
} from '@mdi/js'
import { computed, onBeforeMount, ref } from '@vue/composition-api'
import Http from '@/helpers/http'
import router from '@/router'
import { PREPAYE_TYPE } from '@/helpers/constants/contrat'
import useDynamicConstant from '@/helpers/useDynamicConstant'

export default {
  setup() {
    const statisticsData = [
      {
        title: 'Actifs',
        total: '15',
      },
      {
        title: 'A_échéance',
        total: '5',
      },
      {
        title: 'Echus',
        total: '15',
      },

    ]

    const financialAmount = ref([])
    const amountBalance = ref({})
    const expenseAmount = ref(0)

    const { CURRENCY } = useDynamicConstant()
    console.log('currency', CURRENCY)
    const currency = ref(CURRENCY)

    const withCurrency = price => `${Number(price).toLocaleString()} ${CURRENCY}`
    const contrat = JSON.parse(localStorage.getItem('defaultContrat'))
    const typeContrat = contrat.customer_type_id

    const isPrepaye = computed(() => PREPAYE_TYPE === typeContrat)

    const loadFinancialAmount = () => {
      Http.get(`account-balance-for-segment/${JSON.parse(localStorage.getItem('defaultContrat')).id}`)
        .then(response => {
          financialAmount.value = response.data

          if (!Array.isArray(financialAmount.value)) {
            amountBalance.value = financialAmount.value.balance
          } else {
            amountBalance.value = { balance: 0 }
          }
        })
        .catch(error => {
          console.log(error.data)
        })
    }

    const loadDispenseAmount = () => {
      Http.get(`get-depenses/${contrat.id}`)
        .then(response => {
          expenseAmount.value = response.data
        })
        .catch(error => {
          console.log(error.data)
        })
    }

    const totalExpenses = computed(() => expenseAmount.value.DepensesFuturNotProgram + expenseAmount.value.DepensesFuturProgram)

    onBeforeMount(() => {
      loadFinancialAmount()
      loadDispenseAmount()
    })

    const rechargeForm = () => {
      router.push({ name: 'form-recharge' })
    }

    const debtForm = () => {
      router.push({ name: 'etat-dette' })
    }

    const gotoEcheanceList = () => {
      router.push({ name: 'list-decodeur-echeance' })
    }

    const gotoEtatDette = () => {
      router.push({ name: 'etat-dette' })
    }

    const listeOperation = () => {
      router.push({ name: 'list-operation' })
    }

    const listeOperationTraite = () => {
      router.push({ name: 'list-operation-treated' })
    }

    const resolveStatisticsIconVariation = data => {
      if (data === 'Actifs') return { icon: mdiCheck, color: 'success' }
      if (data === 'A_échéance') return { icon: mdiTrendingDown, color: 'warning' }
      if (data === 'Echus') return { icon: mdiClose, color: 'error' }

      return { icon: mdiCheck, color: 'success' }
    }

    return {
      statisticsData,
      resolveStatisticsIconVariation,

      loadFinancialAmount,
      loadDispenseAmount,
      financialAmount,
      expenseAmount,
      currency,
      withCurrency,
      rechargeForm,
      totalExpenses,
      typeContrat,
      isPrepaye,
      listeOperation,
      listeOperationTraite,

      gotoEcheanceList,
      gotoEtatDette,
      debtForm,
      amountBalance,

      // icons
      icons: {
        mdiClose,
        mdiCheck,
        mdiTrendingDown,

      },
    }
  },
}
</script>
<style scoped>
.grey{
  background: #ded9d9 !important;
  padding: .5rem;
}

.error{
  background: #ff4c51 !important;
  padding: .5rem;
}

.vert{
  color: #44b924;
}

.rouge{
  color: rgba(234, 8, 25, 0.99);
}

.orange{
  color: rgba(238, 93, 61, 0.99);
}
</style>
