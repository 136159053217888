<template>
  <v-card class='mt-4'>

<!--    <v-carousel-->
<!--      :continuous="true"-->
<!--      :cycle="true"-->
<!--      :interval="5000"-->
<!--      hide-delimiters>-->
<!--      <v-carousel-item-->
<!--        v-for="(item,i) in pubs"-->
<!--        :key="i"-->
<!--      >-->

<!--        <v-sheet-->
<!--          tile-->
<!--        >-->
<!--          <v-img :src="item.contenu" height="350"></v-img>-->

<!--            <v-card-title>-->
<!--              {{ item.titre }}-->
<!--            </v-card-title>-->
<!--            <v-card-text>-->
<!--              {{ item.description }}-->
<!--            </v-card-text>-->
<!--        </v-sheet>-->
<!--      </v-carousel-item>-->
<!--    </v-carousel>-->

    <video
      playsinline
      autoplay
      muted
      loop
      poster="../../../assets/images/misc/image_login.gif"
      width="100%"
      max-height="250px"
    >
      <source
        :src="video"
        type="video/mp4"
      >
    </video>
    <v-card-title>
      Accueil
    </v-card-title>
    <v-card-text>
      Bienvenue sur SYGALIN TVSAT CORPORATE
    </v-card-text>
  </v-card>

</template>

<script>
import { ref } from '@vue/composition-api'
import useVuetify from '@core/utils/vuetify'
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiCartPlus,
  mdiShareVariantOutline,
  mdiLockOpenOutline,
  mdiStarOutline,
  mdiAccountOutline,
  mdiTrendingUp,
  mdiHelpCircleOutline,
} from '@mdi/js'
import video from '@/assets/images/misc/corporatevideo.mp4'

export default {
  setup() {
    const { rootThemeClasses } = useVuetify()
    const isCardDetailsVisible = false
    const rating = ref(5)
    const pubs = [
      {
        titre:"le titre 1",
        contenu:'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
        description:'Cancun is backdicts Cancun will draw as many visitors in 2006 as it did two years ago.'
      },
      {
        titre:"le titre 2",
        contenu:'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
        description:'Cancun is back, better than006 as it did two years ago.'
      },
      {
        titre:"le titre 3",
        contenu:'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
        description:'Cancun is back, better than ever! Over a hundred Mexico resorts have reopened and the state tourism minister predicts Cancun will draw as many visitors in 2006 as it did two years ago.'
      },
      {
        titre:"le titre 4",
        contenu:'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
        description:'Cancun is back, better than ever! Over a hundred Mexico rminister predicts Cancun ors in 2006 as it did two years ago.'
      },
    ]

    return {
      rootThemeClasses,
      isCardDetailsVisible,
      rating,
      model: 0,
      pubs,
      video,

      // icons
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiCartPlus,
        mdiShareVariantOutline,
        mdiLockOpenOutline,
        mdiStarOutline,
        mdiAccountOutline,
        mdiTrendingUp,
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}
.membership-pricing {
  text-align: center;
  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}

.texte-slide{
  background: black;
  opacity: .5;
  color: white;
}

.text-white{
  color: white !important;
}
</style>
