<template>
  <v-row
    v-if="!isSupAdmin"
  >
    <v-col
      cols="12"
    >
      <Card-Etat-Dette></Card-Etat-Dette>
    </v-col>

    <v-col
      cols="12"
      md="8"
    >
      <liste-Decodeur></liste-Decodeur>
      <Card-Etat-Ticket></Card-Etat-Ticket>
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <Card-Total-Decodeur></Card-Total-Decodeur>
      <Card-Pub></Card-Pub>
    </v-col>

    <v-col
      cols="12"
      md="8"
    >
    </v-col>
  </v-row>
</template>

<script>
import { mdiPoll, mdiLabelVariantOutline } from '@mdi/js'
import useDynamicConstant from '@/helpers/useDynamicConstant'
import Session from '@/helpers/session'
import CardTotalDecodeur from './analytics/CardTotalDecodeur.vue'
import CardPub from './analytics/CardPub.vue'
import CardEtatDette from './analytics/CardEtatDette.vue'
import CardEtatTicket from './analytics/CardEtatTicket.vue'
import listeDecodeur from './analytics/listeDecodeurEcheance.vue'

export default {
  components: {
    CardTotalDecodeur,
    CardEtatDette,
    listeDecodeur,
    CardPub,
    CardEtatTicket,
  },
  setup() {
    const profitLineChart = {
      statTitle: 'Total Profit',
      statistics: '$86.4k',
      series: [
        {
          data: [0, 20, 5, 30, 15, 45],
        },
      ],
    }

    const totalProfitOptions = {
      statTitle: 'Total Profit',
      icon: mdiPoll,
      color: 'secondary',
      subtitle: 'Weekly Project',
      statistics: '$25.6k',
      change: '+42%',
    }

    // vertical card options
    const newProjectOptions = {
      statTitle: 'New Project',
      icon: mdiLabelVariantOutline,
      color: 'primary',
      subtitle: 'Yearly Project',
      statistics: '862',
      change: '-18%',
    }

    const sessionBarChart = {
      statTitle: 'Sessions',
      statistics: '2,856',
      series: [
        {
          name: '2020',
          data: [45, 85, 65, 50, 70],
        },
      ],
    }

    const { isSuperAdmin } = useDynamicConstant()

    const userData = JSON.parse(Session.get('userData'))

    const role = userData.roles[0]

    console.log('role admin', role.id)

    const isSupAdmin = isSuperAdmin(role.id)

    console.log('role admin', isSupAdmin)

    return {
      profitLineChart,
      totalProfitOptions,
      newProjectOptions,
      sessionBarChart,
      isSupAdmin,
    }
  },
}
</script>
