<template>
  <div class="mt-4">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="align-start grey">
            <h4 class="text-bold">
              Listes des tickets en cours
            </h4>
            <v-tooltip
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2 bold"
                  icon
                  v-bind="attrs"
                  primary
                  outlined
                  x-small
                  v-on="on"
                >
                  <h2>?</h2>
                </v-btn>
              </template>
              <span>Ensemble des tickets envoyés étant en cours de traitement </span>
            </v-tooltip>
          </v-card-title>
          <v-card-text class="pt-4">
            <v-expansion-panels
              v-model="panel"
              multiple
              focusable
            >
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-expansion-panel key="one">
                    <v-expansion-panel-header
                      @click="showTotalFinance = !showTotalFinance"
                    >
                      <h3><b>Finances</b></h3>
                      <template #actions>
                        <v-scroll-x-reverse-transition v-if="showTotalFinance">
                          <v-badge
                            :content="totalFinanceText"
                            class="mr-3 mt-2"
                          >
                          </v-badge>
                        </v-scroll-x-reverse-transition>
                        <v-scroll-x-transition v-else>
                          <span></span>
                        </v-scroll-x-transition>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content v-if="!isPrepaye">
                      <span v-if="debt"
                            class='cursor-pointer'
                            @click="listeFacture"
                      >
                        Paiement de facture <v-badge
                          :content="billText"
                          color="primary"
                          class="mr-3 d-flex flex-row-reverse"
                        >
                        </v-badge>
                      </span>
                      <br v-if="caution">
                      <span v-if="caution"
                            class='cursor-pointer'
                            @click="listeCaution"
                      >
                        Paiement de caution <v-badge
                          :content="cautionText"
                          color="primary"
                          class="mr-3 d-flex flex-row-reverse text--white"
                        >
                        </v-badge>
                      </span>
                      <span
                        v-if="!caution && !debt"
                        class="font-weight-semibold grey--text text-body-1 opacity-4"
                      >
                        Aucune donnée disponible
                      </span>
                    </v-expansion-panel-content>
                    <v-expansion-panel-content v-else>
                      <span v-if="refill"
                            class='cursor-pointer'
                            @click="listeRecharge"
                      >
                        Recharge du compte
                        <v-badge
                          :content="refillText"
                          color="primary"
                          class="mr-3 d-flex flex-row-reverse text--white"
                        >
                        </v-badge>
                      </span>
                      <span v-else class="font-weight-semibold grey--text text-body-1 opacity-4">
                        Aucune donnée disponible
                      </span>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-expansion-panel key="two">
                    <v-expansion-panel-header
                      @click="showTotalActivation = !showTotalActivation"
                    >
                      <h3><b>Opérations</b></h3>
                      <template #actions>
                        <v-scroll-x-reverse-transition
                          v-if="showTotalActivation"
                        >
                          <v-badge
                            :content="totalActivationText"
                            class="mr-3 mt-2"
                          >
                          </v-badge>
                        </v-scroll-x-reverse-transition>

                        <v-scroll-x-transition v-else>
                          <span></span>
                        </v-scroll-x-transition>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content v-if="operationCountByType.length">
                      <span
                        v-for="item in operationCountByType"
                        :key="item.name"
                        class="d-inline-block w-full pt-2 pb-2 cursor-pointer"
                        @click="listeOperation"
                      >
                        {{ item.name }} <v-badge
                          :content="item.countText"
                          color="primary"
                          class="mr-3 d-flex flex-row-reverse"
                        >
                        </v-badge>
                      </span>
                      <!--                      <br>-->
                      <!--                      <span>-->
                      <!--                        Upgrade <v-badge-->
                      <!--                          :content="totalPendingText"-->
                      <!--                          color="primary"-->
                      <!--                          class="mr-3 d-flex flex-row-reverse"-->
                      <!--                        >-->
                      <!--                        </v-badge>-->
                      <!--                      </span>-->
                    </v-expansion-panel-content>
                    <v-expansion-panel-content v-else>
                      <span class="font-weight-semibold grey--text text-body-1 opacity-4">
                        Aucune donnée disponible
                      </span>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-expansion-panel key="three">
                    <v-expansion-panel-header
                      @click="showTotalDecodeur = !showTotalDecodeur"
                    >
                      <h3><b>Validation</b></h3>
                      <template #actions>
                        <v-scroll-x-reverse-transition v-if="showTotalDecodeur">
                          <v-badge
                            :content="totalDecodeurText"
                            class="mr-3 mt-2"
                          >
                          </v-badge>
                        </v-scroll-x-reverse-transition>
                        <v-scroll-x-transition v-else>
                          <span></span>
                        </v-scroll-x-transition>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content v-if="!isPrepaye">
                      <span
                            class='cursor-pointer'
                            @click="listDecodeurConfirm"
                      >
                        Ajout des décodeurs <v-badge
                        :content="billText"
                        color="primary"
                        class="mr-3 d-flex flex-row-reverse"
                      >
                        </v-badge>
                      </span>
                      <br v-if="caution">
                      <span v-if="caution"
                            class='cursor-pointer'
                            @click="listDecodeurConfirm"
                      >
                        Suppression des décodeurs <v-badge
                        :content="cautionText"
                        color="primary"
                        class="mr-3 d-flex flex-row-reverse text--white"
                      >
                        </v-badge>
                      </span>
                      <span
                        v-if="!caution && !debt"
                        class="font-weight-semibold grey--text text-body-1 opacity-4"
                      >
                        Aucune donnée disponible
                      </span>
                    </v-expansion-panel-content>
                    <v-expansion-panel-content v-else>
                      <span v-if="refill"
                            class='cursor-pointer'
                            @click="listDecodeurConfirm"
                      >
                        Recharge du compte
                        <v-badge
                          :content="refillText"
                          color="primary"
                          class="mr-3 d-flex flex-row-reverse text--white"
                        >
                        </v-badge>
                      </span>
                      <span v-else class="font-weight-semibold grey--text text-body-1 opacity-4">
                        Aucune donnée disponible
                      </span>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-col>
              </v-row>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiTrendingDown,
  mdiCheck,
  mdiClose,
} from '@mdi/js'
import Http from '@/helpers/http'
import { computed, onBeforeMount, ref } from '@vue/composition-api'
import { PREPAYE_TYPE } from '@/helpers/constants/contrat'
import router from '@/router'

export default {
  setup() {
    const statisticsData = [
      {
        title: 'Actifs',
        total: '15',
      },
      {
        title: 'A_échéance',
        total: '5',
      },
      {
        title: 'Echus',
        total: '15',
      },

    ]

    const resolveStatisticsIconVariation = data => {
      if (data === 'Actifs') return { icon: mdiCheck, color: 'success' }
      if (data === 'A_échéance') return { icon: mdiTrendingDown, color: 'warning' }
      if (data === 'Echus') return { icon: mdiClose, color: 'error' }

      return { icon: mdiCheck, color: 'success' }
    }

    const isPrepaye = computed(() => PREPAYE_TYPE === JSON.parse(localStorage.getItem('defaultContrat')).customer_type_id)

    /*
    * Variables total section
    * */

    const totalPending = ref(0)
    const totalInProcess = ref(0)
    const totalActivation = ref(0)
    const totalFinance = ref(0)
    const totalPendingText = computed(() => Number(totalPending.value).toLocaleString())
    const totalInProcessText = computed(() => Number(totalInProcess.value).toLocaleString())
    const totalText = computed(() => Number((totalPending.value + totalInProcess.value)).toLocaleString())
    const totalActivationText = computed(() => Number(totalActivation.value).toLocaleString())
    const totalFinanceText = computed(() => Number(totalFinance.value).toLocaleString())
    const totalDecodeurText = computed(() => Number(totalFinance.value).toLocaleString())


    const showTotalFinance = ref(true)
    const showTotalActivation = ref(true)
    const showTotalDecodeur = ref(true)

    /* ***************************************
    *  Finances
    * ************************************** */
    const refill = ref(0)
    const caution = ref(0)
    const debt = ref(0)
    const refillText = computed(() => Number(refill.value).toLocaleString())
    const cautionText = computed(() => Number(totalPending.value).toLocaleString())
    const billText = computed(() => Number(debt.value).toLocaleString())

    /*
    * Recharge du compte financier
    * */
    const loadPendingRefill = () => {
      Http.get(`list-versement-to-treat/${JSON.parse(localStorage.getItem('defaultContrat')).id}`)
        .then(response => {
          refill.value = response.data.length
          totalFinance.value += refill.value
        })
        .catch(error => {
          console.log(error)
        })
    }

    /*
    * Paiement de facture
    * */
    const loadDebtList = () => {
      Http.get(
        `list-customer-segment-debt/${JSON.parse(localStorage.getItem('defaultContrat')).id}`,
      ).then(rep => {
        debt.value += rep.data.length
        totalFinance.value += debt.value

        console.log('totalFinance debt')
        console.log(debt.value)
      }).catch(error => {
        console.error('Erreur :', error)
      })
    }

    /*
    * Paiement des caution encours
    * */
    const loadPayment = () => {
      Http.get(
        `list-bond-to-treat/${JSON.parse(localStorage.getItem('defaultContrat')).id}`,
      ).then(response => {
        caution.value = response.data.length
        totalFinance.value += caution.value
        console.log('totalFinance caution')
        console.log(caution.value)
        loadDebtList()
      }).catch(error => {
        console.error('Erreur :', error)
      })
    }

    /* **************************************
    * Opérations
    * *************************************** */
    const operation = ref([])
    const operationCountByType = ref([])

    /*
    * Liste des opérations en cours
    * */
    const loadPendingRequest = () => {
      Http.get(
        `get-service-request/${JSON.parse(localStorage.getItem('defaultContrat')).id}`,
      ).then(response => {
        console.log('response count')
        console.log(response.data)
        console.log('opérations count')
        console.log(operation.value)
        let i = 0
        let nbr = 0;
        if(response.data.length){

          for (i = 0; i < operation.value.length; i++) {
            operationCountByType.value.push({
              name: operation.value[i].name.charAt(0) + operation.value[i].name.substr(1).toLowerCase(),
              countText: (response.data.filter(item => item.request_type_id === operation.value[i].id).length).toString(),
              count: response.data.filter(item => item.request_type_id === operation.value[i].id).length,
            })

            nbr += response.data.filter(item => item.request_type_id === operation.value[i].id).length;
        }


          // console.log('operationCountByType.value')
          // console.log(operationCountByType.value)
          // operationCountByType.value = operationCountByType.value.filter(item => item.count !== 0)
          //
          // operationCountByType.value.forEach(item => {
          //   totalActivation.value += item.count
          // })
        }
        totalActivation.value += nbr
      }).catch(error => {
        console.log(error)
      })
    }

    /*
    * Liste des types d'opérations
    * */
    const loadOperations = () => {
      Http.get('request-type')
        .then(response => {
          operation.value = response.data
          loadPendingRequest()
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    const listeRecharge = () => {
      router.push({ name: 'list-recharge' })
    }

    const listeFacture = () => {
      router.push({ name: 'liste-facture' })
    }

    const listeCaution = () => {
      router.push({ name: 'list-caution-encours' })
    }

    const listeOperation = () => {
      router.push({ name: 'list-operation' })
    }
    const listDecodeurConfirm = () => {
      router.push({ name: 'list-decodeur-confirm' })
    }

    onBeforeMount(() => {
      loadOperations()
      if (isPrepaye.value) {
        loadPendingRefill()
      } else {
        loadPayment()
      }
    })

    return {
      panel: [],
      statisticsData,
      resolveStatisticsIconVariation,

      loadPendingRefill,
      loadPendingRequest,
      loadPayment,

      totalPendingText,
      totalPending,
      totalText,

      listeRecharge,
      listeFacture,
      listeCaution,
      listeOperation,
      listDecodeurConfirm,


      totalInProcess,
      totalInProcessText,

      refillText,
      cautionText,
      billText,

      refill,
      caution,
      debt,
      operationCountByType,

      totalActivationText,
      totalFinanceText,
      totalDecodeurText,
      isPrepaye,

      showTotalFinance,
      showTotalActivation,
      showTotalDecodeur,

      // icons
      icons: {
        mdiClose,
        mdiCheck,
        mdiTrendingDown,

      },
    }
  },
}
</script>
<style scoped>
.grey {
  background: #ded9d9 !important;
  padding: .5rem;
}

.vert {
  color: #44b924;
}

.rouge {
  color: rgba(234, 8, 25, 0.99);
}

.orange {
  color: rgba(238, 93, 61, 0.99);
}
</style>
