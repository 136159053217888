<template>
  <v-sheet
    v-if="!decodeurList.length && loading"
    class="pa-3"
  >
    <!--    <v-sheet-->
    <!--      color="primary"-->
    <!--      class="pa-3"-->
    <!--      width="100px"-->
    <!--    ></v-sheet>-->
    <!--    <v-sheet-->
    <!--      color="grey"-->
    <!--      class="pa-3"-->
    <!--    ></v-sheet>-->
    <!--    <v-sheet-->
    <!--      color="grey"-->
    <!--      class="pa-3"-->
    <!--    ></v-sheet>-->
    <!--    -->
    <v-skeleton-loader
      class="mx-auto"
      max-height="100px"
      type="card"
    ></v-skeleton-loader>
  </v-sheet>
  <v-card v-else>
    <v-card-title class="align-start">
      <span class="font-weight-semibold"><b text-m>{{ decoderNumber }}</b> <small class="text-sm">Décodeurs au total</small></span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col
            v-for="data in decoderFomatInfo"
            :key="data.title"
            cols="12"
            sm="4"
            class="d-flex align-center"
            @click="gotoFilterList(data.qState)"
          >

            <v-avatar
              size="44"
              :color="resolveStatisticsIconVariation (data.title).color"
              rounded
              class="elevation-1 cursor-pointer"
            >
              <v-icon
                dark
                color="white"
                size="30"
                :ripple="false"
              >
                {{ resolveStatisticsIconVariation (data.title).icon }}
              </v-icon>
            </v-avatar>
            <div
              class="ms-3 cursor-pointer"
            >
              <p class="text-xs mb-0 hidden-md-only">
                {{ data.title }}
              </p>
              <h3 class="text-xl font-weight-semibold">
                {{ data.total }}
              </h3>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiTrendingDown,
  mdiCheck,
  mdiClose,
} from '@mdi/js'
import {
  computed, onBeforeMount, onBeforeUpdate, onMounted, onUpdated, ref,
} from '@vue/composition-api'
import Http from '@/helpers/http'
import moment from 'moment/moment'
import router from '@/router'

export default {
  setup() {
    const statisticsData = [
      {
        title: 'Actifs',
        total: '15',
      },
      {
        title: 'A échéance',
        total: '5',
      },
      {
        title: 'Echus',
        total: '15',
      },

    ]

    const loading = ref(true)

    const resolveStatisticsIconVariation = data => {
      if (data === 'Actifs') return { icon: mdiCheck, color: 'success' }
      if (data === 'A échéance') return { icon: mdiTrendingDown, color: 'warning' }
      if (data === 'Échus') return { icon: mdiClose, color: 'error' }

      return { icon: mdiCheck, color: 'success' }
    }

    /**
     * Decodeurs
     */

    // const userData = JSON.parse(Session.get('userData'))
    const decodeurList = ref([])

    const loadDecoders = () => {
      // console.log(userData)
      // const params = {
      //   roleId: JSON.parse(localStorage.getItem('defaultRole')).id,
      //   contractId: JSON.parse(localStorage.getItem('defaultContrat')).id,
      // }
      // console.log(params)
      Http.get(`get-decoders/${JSON.parse(localStorage.getItem('defaultContrat')).id}`)
        // eslint-disable-next-line no-return-assign
        .then(response => {
          console.log('decodeurs response.data')
          console.log(response.data)
          decodeurList.value = response.data
        })
        .catch(error => {
          console.log(error.data)
        })
    }

    const decoderFomatInfo = computed(() => {
      const tab = decodeurList.value
      let result = {
        actif: 0, echeance: 0, echu: 0,
      }
      // eslint-disable-next-line no-use-before-define
      if (decoderNumber.value > 0) {
        tab.forEach(item => {
          const end = moment(item.end_subscription).format('YYYY-MM-DD')
          const today = moment().format('YYYY-MM-DD')

          console.log("end",end)
          console.log("today",today)
          console.log("diff",moment(end).diff(today, 'days'))

          if (moment(end).diff(today, 'days') < 0) {
          // eslint-disable-next-line no-plusplus
            result.echu++
          } else if (moment(end).diff(today, 'days') >= 0 && moment(end).diff(today, 'days') <= 5) {
          // eslint-disable-next-line no-plusplus
            result.echeance++
          } else {
          // eslint-disable-next-line no-plusplus
            result.actif++
          }
        })
      }
      result = [
        {
          title: 'Actifs',
          total: result.actif,
          qState: 'actif',
        },
        {
          title: 'A échéance',
          total: result.echeance,
          qState: 'echeance',
        },
        {
          title: 'Échus',
          total: result.echu,
          qState: 'echu',
        },
      ]

      return result
    })

    // eslint-disable-next-line camelcase
    const gotoFilterList = query_value => {
      router.push({ name: 'list-decodeur', params: { id: 'all' }, query: { qState: query_value } })
    }

    const decoderNumber = computed(() => {
      const val = decodeurList.value

      return val.length
    })

    onBeforeMount(() => {
      loadDecoders()
    })

    onMounted(() => {
      loading.value = false
    })

    return {
      statisticsData,
      resolveStatisticsIconVariation,

      onBeforeMount,
      decodeurList,
      decoderFomatInfo,
      gotoFilterList,
      decoderNumber,
      loading,

      // icons
      icons: {
        mdiClose,
        mdiCheck,
        mdiTrendingDown,

      },
    }
  },
}
</script>
